<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive="sm">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }" />
      </template>
      <template #cell(range_male)="data">
        <span>{{
          data.item.start_range_male +
          " - " +
          data.item.end_range_male +
          " " +
          data.item.uom
        }}</span>
      </template>
      <template #cell(range_female)="data">
        <span>{{
          data.item.start_range_female +
          " - " +
          data.item.end_range_female +
          " " +
          data.item.uom
        }}</span>
      </template>

      <template #cell(actions)="data">
        <template v-if="purpose == 'modal-field-multiple'">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="
              chooseField(
                {
                  id: data.item.id,
                  name: data.item.name,
                  show_name: data.item.show_name,
                  start_range_male: data.item.start_range_male,
                  end_range_male: data.item.end_range_male,
                  start_range_female: data.item.start_range_female,
                  end_range_female: data.item.end_range_female,
                  uom: data.item.uom,
                },
                $event
              )
            ">
          </b-form-checkbox>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="mt-4"></b-pagination>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  props: {
    purpose: String,
    fieldCollection: {
      type: Array,
      default: [],
    },
  },

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "show_name",
          label: "Nama Alias",
          sortable: true,
        },
        {
          key: "range_male",
          label: "Nilai Normal Laki-laki",
          sortable: true,
        },
        {
          key: "range_female",
          label: "Nilai Normal Perempuan",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "fields",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.setCheckedItem();
    },

    chooseField(data, evt) {
      if (evt) {
        this.$emit("chooseField", data);
      } else {
        this.$emit("unCheckField", data);
      }
    },

    setCheckedItem() {
      if (this.fieldCollection.length > 0) {
        let found;
        this.items.forEach((value) => {
          found = this.fieldCollection.find((x) => x.field_id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
      // localStorage.setItem("dataSelectedPackage", JSON.stringify(this.itemProps))
    },

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("fields/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Field" }]);
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>
