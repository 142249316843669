var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '20%' : '' })})})}},{key:"cell(range_male)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.start_range_male + " - " + data.item.end_range_male + " " + data.item.uom))])]}},{key:"cell(range_female)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.start_range_female + " - " + data.item.end_range_female + " " + data.item.uom))])]}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'modal-field-multiple')?[_c('b-form-checkbox',{attrs:{"checked":data.item.checked,"id":`checkbox-pick-item-${data.item.id}`,"name":`checkbox-pick-item-${data.item.id}`},on:{"change":function($event){return _vm.chooseField(
              {
                id: data.item.id,
                name: data.item.name,
                show_name: data.item.show_name,
                start_range_male: data.item.start_range_male,
                end_range_male: data.item.end_range_male,
                start_range_female: data.item.start_range_female,
                end_range_female: data.item.end_range_female,
                uom: data.item.uom,
              },
              $event
            )}}})]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }