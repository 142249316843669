<template>
  <div>
    <b-modal
      id="modal-field-multiple"
      size="xl"
      title="Pilih Parameter Pemeriksaan Klinik"
      hide-footer
    >
      <Table purpose="modal-field-multiple" @unCheckField="unCheckField" @chooseField="chooseField" :fieldCollection="fieldCollection"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/masters/fields/Table.vue";

export default {
  props: {
      fieldCollection: Array
  },

  components: {
    Table,
  },

  methods: {
    chooseField(value) {
      this.$emit("chooseField", value);
    },
    unCheckField(value){
      this.$emit("unCheckField", value)
    }
  },
};
</script>

<style>
</style>
