<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Template"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <b-form-group id="input-group-opening_sentences">
        <label for="input-opening_sentences">Kalimat pembuka: </label>
        <b-form-textarea
          id="input-opening_sentences"
          v-model="form.opening_sentences"
          placeholder="Kalimat Pembuka"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.opening_sentences }}</small>
      </b-form-group>

      <!-- Input Doctor -->
      <!-- <b-form-group
        id="input-group-field-ids"
        label="Parameter Pemeriksaan Klinik:"
        label-for="input-field-ids"
      >
        <treeselect
          v-model="form.field_ids"
          :multiple="true"
          :options="fields"
          @select="selectedField"
        />
        <small class="text-danger">{{ error.field_ids }}</small>
      </b-form-group> -->

      <div class="row">
        <div class="col-sm-12">
          <b-button
            variant=""
            style="background: #245590"
            class="text-white"
            block
            @click="$bvModal.show('modal-field-multiple')"
          >
            Tambah Parameter Pemeriksaan Klinik
          </b-button>
        </div>
        <div class="col-sm-12 pt-2">
          <table class="table thead-light">
            <thead class="thead-light">
              <tr>
                <th>No</th>
                <!-- <th>Parameter Pemeriksaan Klinik</th> -->
                <th>Nama Parameter Pemeriksaan Klinik</th>
                <th></th>
              </tr>
            </thead>
            <tr v-for="(field, i) in fieldCollection" :key="i">
              <td>{{ i + 1 }}</td>
              <!-- <td>{{ field.name }}</td> -->
              <td>{{ field.field_show_name }}</td>
              <td>
                <b-button
                  class="ml-2"
                  variant="danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteField(i)"
                  size="sm"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <b-form-group id="input-group-closing_sentences">
        <label for="input-closing_sentences">Kalimat penutup: </label>
        <b-form-textarea
          id="input-closing_sentences"
          v-model="form.closing_sentences"
          placeholder="Kalimat Penutup"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.closing_sentences }}</small>
      </b-form-group>

      <b-form-group id="input-group-authorizer_name">
        <label for="input-authorizer_name">Nama Otorisator: </label>
        <b-form-input
          id="input-authorizer_name"
          v-model="form.authorizer_name"
          placeholder="Nama Otorisator"
        ></b-form-input>
        <small class="text-danger">{{ error.authorizer_name }}</small>
      </b-form-group>

      <b-form-group id="input-group-authorizer_position">
        <label for="input-authorizer_position">Jabatan Otorisator: </label>
        <b-form-input
          id="input-authorizer_position"
          v-model="form.authorizer_position"
          placeholder="Jabatan Otorisator"
        ></b-form-input>
        <small class="text-danger">{{ error.authorizer_position }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button
        type="button"
        class="mr-2 btn-info"
        variant="info"
        @click="setPreview"
      >
        <i class="fas fa-eye px-0"></i> Preview
      </b-button>
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/lab-procedure-2/list')"
      >
        Batal
      </b-button>
    </b-form>

    <ModalFieldMultiple
      @chooseField="chooseField"
      @unCheckField="unCheckField"
      :fieldCollection="fieldCollection"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalFieldMultiple from "@/component/general/ModalFieldMultiple.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    isPreview: Boolean,
  },

  components:{
    ModalFieldMultiple
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        template: "",
        field_ids: "",
        authorizer_name: "",
        authorizer_position: "",
      },
      fields: [{ label: "Pilih Parameter Pemeriksaan Klinik", id: "", isDisabled: true }],
      selected_field_form: {
        field_id: "",
        field_show_name: "",
        template_id: "",
        results: "",
        notes: "",
        uom: "",
        start_range_female: "",
        end_range_female: "",
        start_range_male: "",
        end_range_male: "",
      },
      fieldCollection: [],
      fieldPurpose: "add",
      fieldCollectionDeleted: [],
    };
  },

  methods: {
    async getFields() {
      let response = await module.setTreeSelectField("fields");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.fields = response.data;
        this.fields.unshift({
          label: "Pilih Parameter Pemeriksaan Klinik",
          id: "",
          isDisabled: true,
        });
      }
    },
    selectedField(value) {
      this.selected_field_form.field_id = value.id;
      this.selected_field_form.field_show_name = value.show_name;
      this.selected_field_form.template_id = this.$route.params.templateid;
      this.selected_field_form.start_range_male = value.start_range_male;
      this.selected_field_form.end_range_male = value.end_range_male;
      this.selected_field_form.start_range_female = value.start_range_female;
      this.selected_field_form.end_range_female = value.end_range_female;
      this.selected_field_form.uom = value.uom;

      let selected_fields = { ...this.selected_field_form };
      this.$emit("refreshTable", selected_fields);
    },
    chooseField(value){
      this.fieldPurpose = "add";
      this.selected_field_form.field_id = value.id;
      this.selected_field_form.name = value.name;
      this.selected_field_form.field_show_name = value.show_name;
      this.selected_field_form.template_id = this.$route.params.templateid;
      this.selected_field_form.start_range_male = value.start_range_male;
      this.selected_field_form.end_range_male = value.end_range_male;
      this.selected_field_form.start_range_female = value.start_range_female;
      this.selected_field_form.end_range_female = value.end_range_female;
      this.selected_field_form.uom = value.uom;
      let clone = { ...this.selected_field_form };
      this.fieldCollection.push(clone);
      let selected_fields = { ...this.selected_field_form };
      this.$emit("refreshTable", selected_fields);
    },
    unCheckField(value){
      for (let a = 0; a < this.fieldCollection.length; a++) {
        if (this.fieldCollection[a].field_id == value.id) {
          this.fieldCollection.splice(a, 1);
        }
      }
    },
    deleteField(index) {
      this.fieldCollectionDeleted.push(this.fieldCollection[index].id);
      this.fieldCollection.splice(index, 1);
    },
    setPreview() {
      if (this.isPreview) {
        this.$emit("setPreview", false);
      } else {
        this.$emit("setPreview", true);
      }
    },
    async formOnsubmit() {
      this.form.selected_fields = JSON.stringify(this.fieldCollection)
      if (this.fieldCollectionDeleted.length > 0) {
        this.form.fieldCollectionDeleted = JSON.stringify(
          this.fieldCollectionDeleted
        );
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        this.form.selected_fields = this.fieldCollection
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/lab-procedure-2/list");
      }
    },
  },
  mounted() {
    this.getFields();
    this.fieldCollection = this.form.field_collection
  },
  watch: {
    form: function (newVal, oldVal) {
        this.fieldCollection = this.form.field_collection
    },
  },
};
</script>

